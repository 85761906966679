var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.currentUserTags.length
      ? _c(
          "div",
          _vm._l(_vm.currentUserTags, function(tag) {
            return _c(
              "span",
              { staticClass: "badge rounded-pill bg-light mt-2 mx-1" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-check-label d-flex align-items-center m-1",
                    attrs: { for: "tag-" + tag.id }
                  },
                  [
                    _c("input", {
                      staticClass: "form-check-input me-1 my-0",
                      attrs: {
                        id: "tag-" + tag.id,
                        name: "tag-" + tag.id,
                        type: "checkbox",
                        "data-tag-id": tag.id
                      },
                      domProps: { checked: _vm.checked(tag.id) },
                      on: { change: _vm.onChange }
                    }),
                    _c("span", { staticClass: "text-dark" }, [
                      _vm._v(_vm._s("#" + tag.name))
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      : _c("div", [_c("p", [_vm._v("ハッシュタグが作成されていません。")])]),
    _c("div", { staticClass: "mt-3" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("span", { staticClass: "input-group-text" }, [_vm._v("#")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newTagName,
              expression: "newTagName"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "ハッシュタグ名" },
          domProps: { value: _vm.newTagName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.newTagName = $event.target.value
            }
          }
        }),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { disabled: _vm.newTagName.length === 0 },
            on: { click: _vm.createTag }
          },
          [
            _c("i", { staticClass: "bi bi-plus-circle me-1" }),
            _vm._v("ハッシュタグ作成")
          ]
        )
      ]),
      _vm.errorText
        ? _c(
            "span",
            {
              staticClass: "ms-2",
              staticStyle: { color: "red" },
              attrs: { id: "alert-text" }
            },
            [_vm._v(_vm._s(_vm.errorText))]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }