var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "guestbook-form" } },
    [
      _c("formio", {
        ref: "formio",
        attrs: {
          src: _vm.getFromFields(),
          url: "",
          form: "",
          submission: _vm.getSubmission(),
          options: _vm.setOptions()
        },
        on: { change: _vm.onChange, submit: _vm.submitForm }
      }),
      _c("div", { staticClass: "d-flex" }, [
        _vm.skippableGuestbook === "true"
          ? _c(
              "button",
              {
                staticClass: "btn btn-sm btn-outline-secondary ms-auto",
                attrs: { id: "guestbook-skip-button" },
                on: { click: _vm.skip }
              },
              [
                _c("i", { staticClass: "bi bi-skip-end" }),
                _vm._v("フォームの記入をスキップする")
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }