<template lang='pug'>
div
  div(v-if='enableGuestbook')
    p.mb-1 ゲストブック
    .form-check.form-switch.mb-3.ms-4
      input.form-check-input(id='page-customizer-guestbook-skippable' type='checkbox' v-model='pageCustomizerData.guestbook.skippable' @change='onChange')
      label.form-check-label(for='page-customizer-guestbook-skippable') スキップ可
  div(v-if='enableMinimap')
    p.mb-1 ミニマップ
    .small.ms-4 デフォルトの表示
    .form-check.form-switch.ms-4
      input.form-check-input(id='page-customizer-minimap-expand' type='checkbox' v-model='pageCustomizerData.minimap.expand' @change='onChange')
      label.form-check-label(for='page-customizer-minimap-expand') 展開
    .form-check.form-switch.ms-4
      input.form-check-input(id='page-customizer-minimap-mattertag' type='checkbox' v-model='pageCustomizerData.minimap.mattertag' @change='onChange')
      label.form-check-label(for='page-customizer-minimap-mattertag') Mattertag
    .form-check.form-switch.ms-4
      input.form-check-input(id='page-customizer-minimap-sweep' type='checkbox' v-model='pageCustomizerData.minimap.sweep' @change='onChange')
      label.form-check-label(for='page-customizer-minimap-sweep') Sweep
    .form-check.form-switch.mb-3.ms-4
      input.form-check-input(id='page-customizer-minimap-label' type='checkbox' v-model='pageCustomizerData.minimap.label' @change='onChange')
      label.form-check-label(for='page-customizer-minimap-label') Label
  .form-check.form-switch
    input.form-check-input(id='page-customizer-group-list-display' type='checkbox' v-model='pageCustomizerData.group_list.display' @change='onChange')
    label.form-check-label(for='page-customizer-group-list-display') タグリスト表示
  .form-check.form-switch.mb-3.ms-4
    input.form-check-input(id='page-customizer-group-list-expand' type='checkbox' v-model='pageCustomizerData.group_list.expand' @change='onChange' :disabled='!pageCustomizerData.group_list.display')
    label.form-check-label(for='page-customizer-group-list-expand') デフォルトで展開
  .form-check.form-switch.mb-3
    input.form-check-input(id='page-customizer-display' type='checkbox' v-model='pageCustomizerData.header.display' @change='onChange')
    label.form-check-label(for='page-customizer-display') ヘッダーロゴ表示
  .field.mb-3(v-if='pageCustomizerData.header.display')
    label.form-label ヘッダーロゴ
    i.bi.bi-question-circle-fill.text-secondary.ms-1(
      data-bs-toggle='tooltip' data-bs-placement='bottom'
      title='URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な画像は5MB以下のpng、jpg、gifです。'
    )
    input.form-control(id='page-customizer-logo_url' type='text' placeholder='http://xxx.com/logo.png' v-model='pageCustomizerData.header.logo_url' @keyup='onChange')
    input.form-control.mt-2(type='file' id='showcase_header_logo' name='showcase[header_logo]')
    .header_logo_preview.mt-2(v-if='previewHeaderLogoUrl')
      .form-label.mb-1
        | プレビュー
        .btn.btn-sm.btn-danger.ms-2(@click='onPurgeHeaderLogo' v-if='showPurgeHeaderLogoButton')
          i.bi.bi-trash-fill
      img.img-fluid(:src='previewHeaderLogoUrl')
  .field.mb-3
    label.form-label metaタグ用説明文
    textarea.form-control(id='page-customizer-meta-description' placeholder='100文字程度' v-model='pageCustomizerData.meta.description' @keyup='onChange' @input='onChange')
  .field.mb-3
    label.form-label metaタグ用キーワード
    textarea.form-control(id='page-customizer-meta-keywords' placeholder='3〜5個程度、半角スペース区切り' v-model='pageCustomizerData.meta.keywords' @keyup='onChange' @input='onChange')
  .field.mb-3
    label.form-label ショーケースBGM
    i.bi.bi-question-circle-fill.text-secondary.ms-1(
      data-bs-toggle='tooltip' data-bs-placement='bottom'
      title='音声ファイルまたはSoundCloudのURLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な音声は10MB以下のmp3です。'
    )
    input.form-control(id='page-customizer-bgm-url' type='text' placeholder='http://xxx.com/bgm.mp3' v-model='pageCustomizerData.bgm.url' @keyup='onChange')
    input.form-control.mt-2(type='file' id='showcase_bgm' name='showcase[bgm]')
    .form-check.form-switch.my-3
      input.form-check-input(id='page-customizer-bgm-auto-play' type='checkbox' v-model='pageCustomizerData.bgm.auto_play' @change='onChange')
      label.form-check-label(for='page-customizer-bgm-auto-play') オートプレイ
    .uploaded-bgm.mt-2(v-if='previewBgmUrl')
      .form-label.mb-1
        | プレビュー
        .btn.btn-sm.btn-danger.ms-2(@click='onPurgeBgm' v-if='showPurgeBgmButton')
          i.bi.bi-trash-fill
      div(v-html='audioElement')
      //- TODO: autoplay
</template>
<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        enableCilbee: false,
        enableGuestbook: false,
        enableMinimap: false,
        pageCustomizerData: null,
        purgeBgmUrl: null,
        purgeHeaderLogoUrl: null,
        previewBgmUrl: null,
        previewHeaderLogoUrl: null,
        showPurgeBgmButton: false,
        showPurgeHeaderLogoButton: false,
      }
    },
    created() {
      const data = document.querySelector('#showcase-page-customizer').dataset

      this.enableCilbee = data.enableCilbee === 'true'
      this.enableGuestbook = data.enableGuestbook === 'true'
      this.enableMinimap = data.enableMinimap === 'true'
      this.pageCustomizerData = JSON.parse(data.pageCustomizer)
      this.purgeBgmUrl = data.purgeBgmUrl
      this.purgeHeaderLogoUrl = data.purgeHeaderLogoUrl
      this.previewBgmUrl = data.bgmUrl
      this.previewHeaderLogoUrl = data.headerLogoUrl
      this.showPurgeBgmButton = !!data.purgeBgmUrl
      this.showPurgeHeaderLogoButton = !!data.purgeHeaderLogoUrl
    },
    mounted() {
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    },
    methods: {
      onChange() {
        document.getElementById('showcase_page_customizer').value = JSON.stringify(this.pageCustomizerData)
      },
      onPurgeHeaderLogo() {
        const result = confirm('画像ファイルを削除しますがよろしいですか？')
        if (result) {
          axios.delete(this.purgeHeaderLogoUrl)
          this.previewHeaderLogoUrl = this.pageCustomizerData.header.logo_url ? this.pageCustomizerData.header.logo_url : null
          this.showPurgeHeaderLogoButton = false
        }
      },
      onPurgeBgm() {
        const result = confirm('音声ファイルを削除しますがよろしいですか？')
        if (result) {
          axios.delete(this.purgeBgmUrl)
          this.previewBgmUrl = this.pageCustomizerData.bgm.url ? this.pageCustomizerData.bgm.url : null
          this.showPurgeBgmButton = false
        }
      }
    },
    computed: {
      audioElement() {
        if (this.previewBgmUrl.includes('soundcloud.com')) {
          const src = `https://w.soundcloud.com/player/?url=${this.previewBgmUrl}&auto_play=false&hide_related=true&visual=false}`
          return `<iframe width="100%" height="100" scrolling="no" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" src="${src}"></iframe>`
        } else {
          return `<audio class='w-100' src='${this.previewBgmUrl}' loop controls></audio>`
        }
      }
    }
  }
</script>
