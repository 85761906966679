var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.moduleNames, function(name, i) {
        return [
          _vm.moduleAuthorityData[name]
            ? _c("div", { staticClass: "form-check form-switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.modulesData[name],
                      expression: "modulesData[name]"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: { id: "module-toggle-" + name, type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.modulesData[name])
                      ? _vm._i(_vm.modulesData[name], null) > -1
                      : _vm.modulesData[name]
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.modulesData[name],
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(_vm.modulesData, name, $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.modulesData,
                                name,
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.modulesData, name, $$c)
                        }
                      },
                      _vm.onChange
                    ]
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "module-toggle-" + name }
                  },
                  [_vm._v(_vm._s(_vm.labels[i]))]
                )
              ])
            : _vm._e(),
          _vm.moduleAuthorityData[name] && name === "cilbee"
            ? _c("div", { staticClass: "text-muted" }, [
                _vm._v(
                  "※cilbeeを有効にする場合はドールハウスを有効にして下さい。"
                )
              ])
            : _vm._e()
        ]
      }),
      !Object.keys(_vm.moduleAuthorityData).some(function(key) {
        return _vm.moduleAuthorityData[key]
      })
        ? _c("div", [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("利用可能な機能はありません。")
            ])
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }