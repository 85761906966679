var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.enableGuestbook
      ? _c("div", [
          _c("p", { staticClass: "mb-1" }, [_vm._v("ゲストブック")]),
          _c("div", { staticClass: "form-check form-switch mb-3 ms-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pageCustomizerData.guestbook.skippable,
                  expression: "pageCustomizerData.guestbook.skippable"
                }
              ],
              staticClass: "form-check-input",
              attrs: {
                id: "page-customizer-guestbook-skippable",
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(
                  _vm.pageCustomizerData.guestbook.skippable
                )
                  ? _vm._i(_vm.pageCustomizerData.guestbook.skippable, null) >
                    -1
                  : _vm.pageCustomizerData.guestbook.skippable
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.pageCustomizerData.guestbook.skippable,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.pageCustomizerData.guestbook,
                            "skippable",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.pageCustomizerData.guestbook,
                            "skippable",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(
                        _vm.pageCustomizerData.guestbook,
                        "skippable",
                        $$c
                      )
                    }
                  },
                  _vm.onChange
                ]
              }
            }),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "page-customizer-guestbook-skippable" }
              },
              [_vm._v("スキップ可")]
            )
          ])
        ])
      : _vm._e(),
    _vm.enableMinimap
      ? _c("div", [
          _c("p", { staticClass: "mb-1" }, [_vm._v("ミニマップ")]),
          _c("div", { staticClass: "small ms-4" }, [
            _vm._v("デフォルトの表示")
          ]),
          _c("div", { staticClass: "form-check form-switch ms-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pageCustomizerData.minimap.expand,
                  expression: "pageCustomizerData.minimap.expand"
                }
              ],
              staticClass: "form-check-input",
              attrs: { id: "page-customizer-minimap-expand", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.pageCustomizerData.minimap.expand)
                  ? _vm._i(_vm.pageCustomizerData.minimap.expand, null) > -1
                  : _vm.pageCustomizerData.minimap.expand
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.pageCustomizerData.minimap.expand,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "expand",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "expand",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.pageCustomizerData.minimap, "expand", $$c)
                    }
                  },
                  _vm.onChange
                ]
              }
            }),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "page-customizer-minimap-expand" }
              },
              [_vm._v("展開")]
            )
          ]),
          _c("div", { staticClass: "form-check form-switch ms-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pageCustomizerData.minimap.mattertag,
                  expression: "pageCustomizerData.minimap.mattertag"
                }
              ],
              staticClass: "form-check-input",
              attrs: {
                id: "page-customizer-minimap-mattertag",
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.pageCustomizerData.minimap.mattertag)
                  ? _vm._i(_vm.pageCustomizerData.minimap.mattertag, null) > -1
                  : _vm.pageCustomizerData.minimap.mattertag
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.pageCustomizerData.minimap.mattertag,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "mattertag",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "mattertag",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.pageCustomizerData.minimap, "mattertag", $$c)
                    }
                  },
                  _vm.onChange
                ]
              }
            }),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "page-customizer-minimap-mattertag" }
              },
              [_vm._v("Mattertag")]
            )
          ]),
          _c("div", { staticClass: "form-check form-switch ms-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pageCustomizerData.minimap.sweep,
                  expression: "pageCustomizerData.minimap.sweep"
                }
              ],
              staticClass: "form-check-input",
              attrs: { id: "page-customizer-minimap-sweep", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.pageCustomizerData.minimap.sweep)
                  ? _vm._i(_vm.pageCustomizerData.minimap.sweep, null) > -1
                  : _vm.pageCustomizerData.minimap.sweep
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.pageCustomizerData.minimap.sweep,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "sweep",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "sweep",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.pageCustomizerData.minimap, "sweep", $$c)
                    }
                  },
                  _vm.onChange
                ]
              }
            }),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "page-customizer-minimap-sweep" }
              },
              [_vm._v("Sweep")]
            )
          ]),
          _c("div", { staticClass: "form-check form-switch mb-3 ms-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pageCustomizerData.minimap.label,
                  expression: "pageCustomizerData.minimap.label"
                }
              ],
              staticClass: "form-check-input",
              attrs: { id: "page-customizer-minimap-label", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.pageCustomizerData.minimap.label)
                  ? _vm._i(_vm.pageCustomizerData.minimap.label, null) > -1
                  : _vm.pageCustomizerData.minimap.label
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.pageCustomizerData.minimap.label,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "label",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.pageCustomizerData.minimap,
                            "label",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.pageCustomizerData.minimap, "label", $$c)
                    }
                  },
                  _vm.onChange
                ]
              }
            }),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "page-customizer-minimap-label" }
              },
              [_vm._v("Label")]
            )
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-check form-switch" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pageCustomizerData.group_list.display,
            expression: "pageCustomizerData.group_list.display"
          }
        ],
        staticClass: "form-check-input",
        attrs: { id: "page-customizer-group-list-display", type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.pageCustomizerData.group_list.display)
            ? _vm._i(_vm.pageCustomizerData.group_list.display, null) > -1
            : _vm.pageCustomizerData.group_list.display
        },
        on: {
          change: [
            function($event) {
              var $$a = _vm.pageCustomizerData.group_list.display,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.pageCustomizerData.group_list,
                      "display",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.pageCustomizerData.group_list,
                      "display",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.pageCustomizerData.group_list, "display", $$c)
              }
            },
            _vm.onChange
          ]
        }
      }),
      _c(
        "label",
        {
          staticClass: "form-check-label",
          attrs: { for: "page-customizer-group-list-display" }
        },
        [_vm._v("タグリスト表示")]
      )
    ]),
    _c("div", { staticClass: "form-check form-switch mb-3 ms-4" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pageCustomizerData.group_list.expand,
            expression: "pageCustomizerData.group_list.expand"
          }
        ],
        staticClass: "form-check-input",
        attrs: {
          id: "page-customizer-group-list-expand",
          type: "checkbox",
          disabled: !_vm.pageCustomizerData.group_list.display
        },
        domProps: {
          checked: Array.isArray(_vm.pageCustomizerData.group_list.expand)
            ? _vm._i(_vm.pageCustomizerData.group_list.expand, null) > -1
            : _vm.pageCustomizerData.group_list.expand
        },
        on: {
          change: [
            function($event) {
              var $$a = _vm.pageCustomizerData.group_list.expand,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.pageCustomizerData.group_list,
                      "expand",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.pageCustomizerData.group_list,
                      "expand",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.pageCustomizerData.group_list, "expand", $$c)
              }
            },
            _vm.onChange
          ]
        }
      }),
      _c(
        "label",
        {
          staticClass: "form-check-label",
          attrs: { for: "page-customizer-group-list-expand" }
        },
        [_vm._v("デフォルトで展開")]
      )
    ]),
    _c("div", { staticClass: "form-check form-switch mb-3" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pageCustomizerData.header.display,
            expression: "pageCustomizerData.header.display"
          }
        ],
        staticClass: "form-check-input",
        attrs: { id: "page-customizer-display", type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.pageCustomizerData.header.display)
            ? _vm._i(_vm.pageCustomizerData.header.display, null) > -1
            : _vm.pageCustomizerData.header.display
        },
        on: {
          change: [
            function($event) {
              var $$a = _vm.pageCustomizerData.header.display,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.pageCustomizerData.header,
                      "display",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.pageCustomizerData.header,
                      "display",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.pageCustomizerData.header, "display", $$c)
              }
            },
            _vm.onChange
          ]
        }
      }),
      _c(
        "label",
        {
          staticClass: "form-check-label",
          attrs: { for: "page-customizer-display" }
        },
        [_vm._v("ヘッダーロゴ表示")]
      )
    ]),
    _vm.pageCustomizerData.header.display
      ? _c("div", { staticClass: "field mb-3" }, [
          _c("label", { staticClass: "form-label" }, [_vm._v("ヘッダーロゴ")]),
          _c("i", {
            staticClass: "bi bi-question-circle-fill text-secondary ms-1",
            attrs: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "bottom",
              title:
                "URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な画像は5MB以下のpng、jpg、gifです。"
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.pageCustomizerData.header.logo_url,
                expression: "pageCustomizerData.header.logo_url"
              }
            ],
            staticClass: "form-control",
            attrs: {
              id: "page-customizer-logo_url",
              type: "text",
              placeholder: "http://xxx.com/logo.png"
            },
            domProps: { value: _vm.pageCustomizerData.header.logo_url },
            on: {
              keyup: _vm.onChange,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.pageCustomizerData.header,
                  "logo_url",
                  $event.target.value
                )
              }
            }
          }),
          _c("input", {
            staticClass: "form-control mt-2",
            attrs: {
              type: "file",
              id: "showcase_header_logo",
              name: "showcase[header_logo]"
            }
          }),
          _vm.previewHeaderLogoUrl
            ? _c("div", { staticClass: "header_logo_preview mt-2" }, [
                _c("div", { staticClass: "form-label mb-1" }, [
                  _vm._v("プレビュー"),
                  _vm.showPurgeHeaderLogoButton
                    ? _c(
                        "div",
                        {
                          staticClass: "btn btn-sm btn-danger ms-2",
                          on: { click: _vm.onPurgeHeaderLogo }
                        },
                        [_c("i", { staticClass: "bi bi-trash-fill" })]
                      )
                    : _vm._e()
                ]),
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: { src: _vm.previewHeaderLogoUrl }
                })
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c("div", { staticClass: "field mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("metaタグ用説明文")]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pageCustomizerData.meta.description,
            expression: "pageCustomizerData.meta.description"
          }
        ],
        staticClass: "form-control",
        attrs: {
          id: "page-customizer-meta-description",
          placeholder: "100文字程度"
        },
        domProps: { value: _vm.pageCustomizerData.meta.description },
        on: {
          keyup: _vm.onChange,
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.pageCustomizerData.meta,
                "description",
                $event.target.value
              )
            },
            _vm.onChange
          ]
        }
      })
    ]),
    _c("div", { staticClass: "field mb-3" }, [
      _c("label", { staticClass: "form-label" }, [
        _vm._v("metaタグ用キーワード")
      ]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pageCustomizerData.meta.keywords,
            expression: "pageCustomizerData.meta.keywords"
          }
        ],
        staticClass: "form-control",
        attrs: {
          id: "page-customizer-meta-keywords",
          placeholder: "3〜5個程度、半角スペース区切り"
        },
        domProps: { value: _vm.pageCustomizerData.meta.keywords },
        on: {
          keyup: _vm.onChange,
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(
                _vm.pageCustomizerData.meta,
                "keywords",
                $event.target.value
              )
            },
            _vm.onChange
          ]
        }
      })
    ]),
    _c("div", { staticClass: "field mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("ショーケースBGM")]),
      _c("i", {
        staticClass: "bi bi-question-circle-fill text-secondary ms-1",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "bottom",
          title:
            "音声ファイルまたはSoundCloudのURLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な音声は10MB以下のmp3です。"
        }
      }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pageCustomizerData.bgm.url,
            expression: "pageCustomizerData.bgm.url"
          }
        ],
        staticClass: "form-control",
        attrs: {
          id: "page-customizer-bgm-url",
          type: "text",
          placeholder: "http://xxx.com/bgm.mp3"
        },
        domProps: { value: _vm.pageCustomizerData.bgm.url },
        on: {
          keyup: _vm.onChange,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.pageCustomizerData.bgm, "url", $event.target.value)
          }
        }
      }),
      _c("input", {
        staticClass: "form-control mt-2",
        attrs: { type: "file", id: "showcase_bgm", name: "showcase[bgm]" }
      }),
      _c("div", { staticClass: "form-check form-switch my-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pageCustomizerData.bgm.auto_play,
              expression: "pageCustomizerData.bgm.auto_play"
            }
          ],
          staticClass: "form-check-input",
          attrs: { id: "page-customizer-bgm-auto-play", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.pageCustomizerData.bgm.auto_play)
              ? _vm._i(_vm.pageCustomizerData.bgm.auto_play, null) > -1
              : _vm.pageCustomizerData.bgm.auto_play
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.pageCustomizerData.bgm.auto_play,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.pageCustomizerData.bgm,
                        "auto_play",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.pageCustomizerData.bgm,
                        "auto_play",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.pageCustomizerData.bgm, "auto_play", $$c)
                }
              },
              _vm.onChange
            ]
          }
        }),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "page-customizer-bgm-auto-play" }
          },
          [_vm._v("オートプレイ")]
        )
      ]),
      _vm.previewBgmUrl
        ? _c("div", { staticClass: "uploaded-bgm mt-2" }, [
            _c("div", { staticClass: "form-label mb-1" }, [
              _vm._v("プレビュー"),
              _vm.showPurgeBgmButton
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-sm btn-danger ms-2",
                      on: { click: _vm.onPurgeBgm }
                    },
                    [_c("i", { staticClass: "bi bi-trash-fill" })]
                  )
                : _vm._e()
            ]),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.audioElement) } })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }