var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "h6 fw-bold mt-3" }, [
          _vm._v("テンプレート設定")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "list-group list-group-horizontal",
            attrs: { id: "module-cover-page-templates-selected" }
          },
          [
            _c("label", { staticClass: "list-group-item col-4 text-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.coverPageData.templates.selected,
                    expression: "coverPageData.templates.selected"
                  }
                ],
                staticClass: "form-check-input me-1 mb-2",
                attrs: { type: "radio", name: "templateSelector", value: "1" },
                domProps: {
                  checked: _vm._q(_vm.coverPageData.templates.selected, "1")
                },
                on: {
                  change: [
                    function($event) {
                      return _vm.$set(
                        _vm.coverPageData.templates,
                        "selected",
                        "1"
                      )
                    },
                    _vm.onChange
                  ]
                }
              }),
              _vm._v("\n        1. 左揃え\n      ")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "list-group-item col-4 text-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.coverPageData.templates.selected,
                    expression: "coverPageData.templates.selected"
                  }
                ],
                staticClass: "form-check-input me-1 mb-2",
                attrs: { type: "radio", name: "templateSelector", value: "2" },
                domProps: {
                  checked: _vm._q(_vm.coverPageData.templates.selected, "2")
                },
                on: {
                  change: [
                    function($event) {
                      return _vm.$set(
                        _vm.coverPageData.templates,
                        "selected",
                        "2"
                      )
                    },
                    _vm.onChange
                  ]
                }
              }),
              _vm._v("\n        2. 中央揃え\n      ")
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "list-group-item col-4 text-center" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.coverPageData.templates.selected,
                    expression: "coverPageData.templates.selected"
                  }
                ],
                staticClass: "form-check-input me-1 mb-2",
                attrs: { type: "radio", name: "templateSelector", value: "3" },
                domProps: {
                  checked: _vm._q(_vm.coverPageData.templates.selected, "3")
                },
                on: {
                  change: [
                    function($event) {
                      return _vm.$set(
                        _vm.coverPageData.templates,
                        "selected",
                        "3"
                      )
                    },
                    _vm.onChange
                  ]
                }
              }),
              _vm._v("\n        3. 右揃え\n      ")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "h6 fw-bold mt-4" }, [_vm._v("コンテント設定")]),
      _vm._v(" "),
      _c("editor", {
        attrs: {
          "api-key": "ah0l6rfwuia0nog7in2xg6voos3z42g28ut9t3n4p6nuaxxb",
          init: {
            language: "ja",
            height: 500,
            menubar: true,
            toolbar_mode: "wrap",
            content_style:
              "\n        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');\n        @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');\n        body { font-family: Noto Sans JP !important; };\n      ",
            font_formats:
              "Noto Sans JP=Noto Sans JP; Noto Serif JP=Noto Serif JP",
            style_formats: [
              {
                title: "ウェイト",
                items: [
                  {
                    title: "900",
                    inline: "span",
                    styles: { "font-weight": "900" }
                  },
                  {
                    title: "800",
                    inline: "span",
                    styles: { "font-weight": "800" }
                  },
                  {
                    title: "700",
                    inline: "span",
                    styles: { "font-weight": "700" }
                  },
                  {
                    title: "600",
                    inline: "span",
                    styles: { "font-weight": "600" }
                  },
                  {
                    title: "500",
                    inline: "span",
                    styles: { "font-weight": "500" }
                  },
                  {
                    title: "400",
                    inline: "span",
                    styles: { "font-weight": "400" }
                  },
                  {
                    title: "300",
                    inline: "span",
                    styles: { "font-weight": "300" }
                  },
                  {
                    title: "200",
                    inline: "span",
                    styles: { "font-weight": "200" }
                  },
                  {
                    title: "100",
                    inline: "span",
                    styles: { "font-weight": "100" }
                  }
                ]
              },
              {
                title: "ドロップシャドウ",
                items: [
                  {
                    title: "ブラック",
                    inline: "span",
                    styles: { "text-shadow": "rgb(0 0 0 / 65%) 0px 3px 8px" }
                  },
                  {
                    title: "ホワイト",
                    inline: "span",
                    styles: {
                      "text-shadow": "rgb(255 255 255 / 65%) 0px 3px 8px"
                    }
                  }
                ]
              }
            ],
            style_formats_merge: true,
            plugins: [
              "print preview paste searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons"
            ],
            toolbar:
              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect styleselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl"
          }
        },
        on: {
          onKeyUp: _vm.onChange,
          onMouseUp: _vm.onChange,
          onChange: _vm.onChange
        },
        model: {
          value: _vm.coverPageData.content.body,
          callback: function($$v) {
            _vm.$set(_vm.coverPageData.content, "body", $$v)
          },
          expression: "coverPageData.content.body"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "field mt-3" }, [
        _c("label", { staticClass: "form-label" }, [
          _vm._v("再生ボタン用テキスト")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.coverPageData.content.play_button_text,
              expression: "coverPageData.content.play_button_text"
            }
          ],
          staticClass: "form-control mb-2",
          attrs: {
            id: "module-cover-page-content-play-button-text",
            type: "text",
            placeholder: "VRショーケースへ入場する"
          },
          domProps: { value: _vm.coverPageData.content.play_button_text },
          on: {
            keyup: _vm.onChange,
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.coverPageData.content,
                  "play_button_text",
                  $event.target.value
                )
              },
              _vm.onChange
            ]
          }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.coverPageData.content.play_button_bottom,
              expression: "coverPageData.content.play_button_bottom"
            }
          ],
          staticClass: "form-check-input",
          attrs: {
            id: "module-cover-page-content-play-button-only",
            type: "checkbox"
          },
          domProps: {
            checked: Array.isArray(_vm.coverPageData.content.play_button_bottom)
              ? _vm._i(_vm.coverPageData.content.play_button_bottom, null) > -1
              : _vm.coverPageData.content.play_button_bottom
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.coverPageData.content.play_button_bottom,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(
                        _vm.coverPageData.content,
                        "play_button_bottom",
                        $$a.concat([$$v])
                      )
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.coverPageData.content,
                        "play_button_bottom",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.coverPageData.content, "play_button_bottom", $$c)
                }
              },
              _vm.onChange
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label",
            attrs: { for: "module-cover-page-content-play-button-only" }
          },
          [_vm._v("再生ボタンを下部に表示")]
        ),
        _vm._v(" "),
        _c("i", {
          staticClass: "bi bi-question-circle-fill text-secondary",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "コンテントの中身があると再生ボタンは必ず下部に表示されます。"
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field mt-3" }, [
        _c("label", { staticClass: "form-label" }, [_vm._v("背景カラー")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.coverPageData.content.background.color,
              expression: "coverPageData.content.background.color"
            }
          ],
          staticClass: "form-control",
          attrs: {
            id: "module-cover-page-content-bg-color",
            type: "input",
            placeholder: "#FFFFFFFF / rgb(255 255 255 / 100%)"
          },
          domProps: { value: _vm.coverPageData.content.background.color },
          on: {
            keyup: _vm.onChange,
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.coverPageData.content.background,
                  "color",
                  $event.target.value
                )
              },
              _vm.onChange
            ]
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "h6 fw-bold mt-4" }, [_vm._v("背景画像設定")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("背景動画URL")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                title:
                  "URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な動画は30MB以下のmp4、webmです。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.video.url,
                  expression: "coverPageData.background.video.url"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-video-url",
                type: "text",
                placeholder: "http://xxx.com/background-video.mp4"
              },
              domProps: { value: _vm.coverPageData.background.video.url },
              on: {
                keyup: _vm.onChange,
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.coverPageData.background.video,
                      "url",
                      $event.target.value
                    )
                  },
                  _vm.onChange
                ]
              }
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control my-2",
              attrs: {
                type: "file",
                id: "showcase_cover_page_background_video",
                name: "showcase[cover_page_background_video]"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.video.loop,
                  expression: "coverPageData.background.video.loop"
                }
              ],
              staticClass: "form-check-input",
              attrs: {
                id: "module-cover-page-bg-video-loop",
                type: "checkbox"
              },
              domProps: {
                checked: Array.isArray(_vm.coverPageData.background.video.loop)
                  ? _vm._i(_vm.coverPageData.background.video.loop, null) > -1
                  : _vm.coverPageData.background.video.loop
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.coverPageData.background.video.loop,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.coverPageData.background.video,
                            "loop",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.coverPageData.background.video,
                            "loop",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.coverPageData.background.video, "loop", $$c)
                    }
                  },
                  _vm.onChange
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "module-cover-page-bg-video-loop" }
              },
              [_vm._v("ループ再生をする")]
            ),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                title:
                  "オフの場合、動画の最終フレームで停止した状態になります。"
              }
            }),
            _vm._v(" "),
            _vm.previewVideoUrl
              ? _c(
                  "div",
                  { staticClass: "cover_page_background_video_preview mt-2" },
                  [
                    _c("div", { staticClass: "form-label mb-1" }, [
                      _vm._v("\n            プレビュー\n            "),
                      _vm.showPurgeVideoButton
                        ? _c(
                            "div",
                            {
                              staticClass: "btn btn-sm btn-danger ms-2",
                              on: { click: _vm.onPurgeVideo }
                            },
                            [_c("i", { staticClass: "bi bi-trash-fill" })]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("video", {
                      staticClass: "img-fluid",
                      attrs: {
                        autoplay: "",
                        loop: "",
                        controls: "",
                        playsinline: "",
                        muted: "",
                        src: _vm.previewVideoUrl
                      },
                      domProps: { muted: true }
                    })
                  ]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("画像URL")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                title:
                  "URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。動画URLの指定がある場合はそちらが優先されます。アップロード可能な画像は5MB以下のpng、jpg、gifです。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.image_url,
                  expression: "coverPageData.background.image_url"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-image-url",
                type: "text",
                placeholder: "http://xxx.com/background-image.png"
              },
              domProps: { value: _vm.coverPageData.background.image_url },
              on: {
                keyup: _vm.onChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.coverPageData.background,
                    "image_url",
                    $event.target.value
                  )
                }
              }
            }),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control mt-2",
              attrs: {
                type: "file",
                id: "showcase_cover_page_background",
                name: "showcase[cover_page_background]"
              }
            }),
            _vm._v(" "),
            _vm.previewImageUrl
              ? _c(
                  "div",
                  { staticClass: "cover_page_background_preview mt-2" },
                  [
                    _c("div", { staticClass: "form-label mb-1" }, [
                      _vm._v("\n            プレビュー\n            "),
                      _vm.showPurgeImageButton
                        ? _c(
                            "div",
                            {
                              staticClass: "btn btn-sm btn-danger ms-2",
                              on: { click: _vm.onPurgeImage }
                            },
                            [_c("i", { staticClass: "bi bi-trash-fill" })]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: _vm.previewImageUrl }
                    })
                  ]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("サイズ")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                "data-bs-html": "true",
                title:
                  "background-sizeのスタイルを指定出来ます。詳しくは<a href='https://developer.mozilla.org/ja/docs/Web/CSS/background-size' target='_blank'>こちら</a>から。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.size,
                  expression: "coverPageData.background.size"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-size",
                type: "text",
                placeholder: "unset"
              },
              domProps: { value: _vm.coverPageData.background.size },
              on: {
                keyup: _vm.onChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.coverPageData.background,
                    "size",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("リピート")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                "data-bs-html": "true",
                title:
                  "background-repeatのスタイルを指定出来ます。詳しくは<a href='https://developer.mozilla.org/ja/docs/Web/CSS/background-repeat' target='_blank'>こちら</a>から。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.repeat,
                  expression: "coverPageData.background.repeat"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-repeat",
                type: "text",
                placeholder: "unset"
              },
              domProps: { value: _vm.coverPageData.background.repeat },
              on: {
                keyup: _vm.onChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.coverPageData.background,
                    "repeat",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-4" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("ポジション")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                "data-bs-html": "true",
                title:
                  "background-positionのスタイルを指定出来ます。詳しくは<a href='https://developer.mozilla.org/ja/docs/Web/CSS/background-position' target='_blank'>こちら</a>から。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.position,
                  expression: "coverPageData.background.position"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-position",
                type: "text",
                placeholder: "unset"
              },
              domProps: { value: _vm.coverPageData.background.position },
              on: {
                keyup: _vm.onChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.coverPageData.background,
                    "position",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-sm-6" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("カラー")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                title:
                  "パターンカラーを指定してください。「,」区切りでグラデーテョンを指定する事も可能です。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.trianglify.color,
                  expression: "coverPageData.background.trianglify.color"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-trianglify-colors",
                type: "text",
                placeholder:
                  "単色「#cccccc」、グラデーション「#000000,#ffffff」"
              },
              domProps: {
                value: _vm.coverPageData.background.trianglify.color
              },
              on: {
                keyup: _vm.onChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.coverPageData.background.trianglify,
                    "color",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-3" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [_vm._v("セルサイズ")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                title: "セルサイズを指定してください。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.trianglify.size,
                  expression: "coverPageData.background.trianglify.size"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-trianglify-size",
                type: "text",
                placeholder: "75"
              },
              domProps: { value: _vm.coverPageData.background.trianglify.size },
              on: {
                keyup: _vm.onChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.coverPageData.background.trianglify,
                    "size",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-sm-3" }, [
          _c("div", { staticClass: "field mt-3" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("セルサイズ変動値")
            ]),
            _vm._v(" "),
            _c("i", {
              staticClass: "bi bi-question-circle-fill text-secondary",
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "bottom",
                title:
                  "セルサイズの変動値を指定してください。値が大きいほどセルサイズの変化量が上がります。"
              }
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.coverPageData.background.trianglify.variance,
                  expression: "coverPageData.background.trianglify.variance"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: "module-cover-page-bg-trianglify-variance",
                type: "text",
                placeholder: "0.75"
              },
              domProps: {
                value: _vm.coverPageData.background.trianglify.variance
              },
              on: {
                keyup: _vm.onChange,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.coverPageData.background.trianglify,
                    "variance",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-check mt-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.coverPageData.first,
              expression: "coverPageData.first"
            }
          ],
          staticClass: "form-check-input",
          attrs: { id: "module-cover-page-data-first", type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.coverPageData.first)
              ? _vm._i(_vm.coverPageData.first, null) > -1
              : _vm.coverPageData.first
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.coverPageData.first,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.coverPageData, "first", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.coverPageData,
                        "first",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.coverPageData, "first", $$c)
                }
              },
              _vm.onChange
            ]
          }
        }),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "form-check-label fw-bold",
            attrs: { for: "module-cover-page-data-first" }
          },
          [_vm._v("カバーページを最初に表示する")]
        ),
        _vm._v(" "),
        _c("i", {
          staticClass: "bi bi-question-circle-fill text-secondary",
          attrs: {
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title:
              "ゲストブックと併用している時にチェックを入れなかった場合、ゲストブックが最初に表示されます。"
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h6 fw-bold mt-4 mb-0" }, [
      _vm._v("背景パターン設定\n    "),
      _c("i", {
        staticClass: "bi bi-question-circle-fill text-secondary",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "bottom",
          title: "動画・画像URLの指定がある場合はそちらが優先されます。"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }