document.addEventListener('turbolinks:load', () => {
  const target = document.querySelector('#showcase-card-container')
  if (target) {
    const initialize = () => {
      const filterButtons = document.querySelectorAll('.showcase-filter-button')
      filterButtons.forEach(button => {
        button.addEventListener('click', () => {
          const tagId = button.dataset.tagId
          document.getElementById('search-text-field').value = ''
          document.getElementById('search-tag-field').value = tagId
          document.getElementById('search-submit-button').click()
  
          document.querySelector('#showcase-search-container').dataset.tagId = tagId
        })
      })
    }

    // NOTE: 検索やタグでショーケースカードのフィルタリングをした後に'turbolinks:load'が動かないのでobserverを使用することにした
    new MutationObserver(_mutations => {
      initialize()
    }).observe(target, { childList: true, subtree: true })
  
    initialize()
  }
})
