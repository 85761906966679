<template lang='pug'>
.col-12
  .cilbee-result.row.mt-3.mb-1
    .col-12
      .card(v-if='cilbeeRecordings.length')
        .card-body
          .card-title cilbeeデータ
          .card-text
            table.table.table-striped
              thead
                tr
                  th 種類
                  th 開始地点
                  th 目的地
                  th 経路
                  th 時刻
              tbody
                tr(v-for='recording in cilbeeRecordings')
                  td {{ recording.data.cilbee.type === 'cilbee preview' ? 'プレビュー' : '案内' }}
                  td {{ recording.data.cilbee.start }}
                  td(v-html='recording.data.cilbee.dests.join("<br>")')
                  td {{ recording.data.cilbee.route }}
                  td {{ recording.created_at }}

  .row.m-0.g-2
    .col-12.col-sm-6.col-md-4.col-lg-3.col-xl-2.recording-list(v-for='(recording, i) in recordings')
      .btn-group.col-12
        button.btn.recording-selector(
          :class='recordingButtonClass(i)'
          @click='getRecordingData(i)'
        )
          | {{ `ID: ${recording.id}` }}
          br
          | {{ recording.date }}

        .btn-group(data-bs-toggle='tooltip' title='このレコーディングのデータをCSVでダウンロード')
          a.btn.d-flex.justify-content-center.align-items-center.h-100.dropdown-toggle(
            :id='`dropdown-${recording.id}`'
            :class='`${recording.cilbee ? "btn-warning text-white" : "btn-primary"}`'
            :href='`${apiBaseUrl}/${recording.id}/download`'
            aria-expanded='false'
            data-bs-toggle='dropdown'
          )
            i.bi.bi-download
          ul.dropdown-menu(:aria-labelledby='`dropdown-${recording.id}`')
            li
              a.dropdown-item(:href='`${apiBaseUrl}/${recording.id}/download${recording.cilbee ? "?cilbee=true" : ""}`' data-turbolinks='false')
                | UTF-8形式
            li
              a.dropdown-item(:href='`${apiBaseUrl}/${recording.id}/download?encoding=SJIS${recording.cilbee ? "&cilbee=true" : ""}`' data-turbolinks='false')
                | Shift-JIS形式
</template>
<script>
import axios from 'axios'

export default {
  data() {
    return {
      apiBaseUrl: '',
      activeButtonIndex: 0,
      cilbeeRecordings: [],
      csrfHeader: null,
      dpm: null,
      recordings: []
    }
  },
  computed: {
    recordingButtonClass() {
      return i => {
        const active = this.activeButtonIndex === i
        if (this.recordings[i].cilbee) {
          return active ? 'cilbee btn-warning text-white' : 'cilbee btn-outline-warning'
        } else {
          return active ? 'btn-primary' : 'btn-outline-primary'
        }
      }
    }
  },
  async created() {
    this.csrfHeader = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]') ? document.querySelector('meta[name="csrf-token"]').getAttribute('content') : ''
    }

    const showcaseDataset = document.querySelector('#showcase').dataset
    this.dpm = new DriftPhotoModule(showcaseDataset.sid, { ...showcaseDataset, ...{
      urlParams: JSON.stringify({ play: 1, qs: 1 }),
      groupListDrawer: { display: false, expand: false }
    } })
    await this.dpm.load()

    const recordingDataset = document.querySelector('#recording-view-container').dataset
    this.apiBaseUrl = recordingDataset.apiBaseUrl
    this.recordings = JSON.parse(recordingDataset.recordings)

    this.getRecordingData(0)
  },
  methods: {
    async getRecordingData(i) {
      this.activeButtonIndex = i

      const targetRecording = this.recordings[i]
      const res = await axios.get(`${this.apiBaseUrl}/${targetRecording.id}/events_to_json.json`, { headers: this.csrfHeader })
      this.dpm.initEventsPlayer(JSON.stringify(res.data))

      if (targetRecording.cilbee) {
        this.initTable(res.data)
      } else {
        this.cilbeeRecordings = []
      }
    },
    initTable(recordings) {
      recordings.forEach(recording => recording.data = JSON.parse(recording.data))
      this.cilbeeRecordings = recordings.filter(recording => recording.data.cilbee.type !== 'cilbee launch')
    }
  }
}
</script>