document.addEventListener('turbolinks:load', async () => {
  const showcaseIframe = document.querySelector('#showcase:not(.player)')
  if (showcaseIframe) {
    const data = showcaseIframe.dataset
    if (data.showMpShowcase) {
      showcaseIframe.parentElement.classList.remove('d-none')
      const DPM = new DriftPhotoModule(data.sid, data)
      await DPM.load()
    }
  }
})
