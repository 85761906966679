<template>
  <div>
    <editor
      v-model="privacyPolicyData.body"
      @onKeyUp='onChange'
      @onMouseUp='onChange'
      @onChange='onChange'
      api-key="ah0l6rfwuia0nog7in2xg6voos3z42g28ut9t3n4p6nuaxxb"
      :init="{
        language: 'ja',
        height: 500,
        menubar: true,
        plugins: [
          'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
        ],
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl'
      }"
    />
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  data() {
    return {
      privacyPolicyData: {}
    }
  },
  props: ['privacyPolicy'],
  created() {
    const parsedPrivacyPolicy = JSON.parse(this.privacyPolicy)
    for (let key in parsedPrivacyPolicy) {
      this.$set(this.privacyPolicyData, key, parsedPrivacyPolicy[key])
    }
  },
  components: {
    editor: Editor
  },
  methods: {
    onChange() {
      document.getElementById('user_privacy_policy').value = JSON.stringify(this.privacyPolicyData)
    }
  }
}
</script>