<template lang='pug'>
#guestbook-form
  formio(ref='formio' :src='getFromFields()' url='' form='' :submission='getSubmission()' :options='setOptions()' v-on:change='onChange' v-on:submit='submitForm')
  .d-flex
    button#guestbook-skip-button.btn.btn-sm.btn-outline-secondary.ms-auto(v-if='skippableGuestbook==="true"' @click='skip')
      i.bi.bi-skip-end
      | フォームの記入をスキップする
</template>

<script>
  import { Form } from 'vue-formio'
  import axios from 'axios'

  export default {
    name: 'app',
    components: { formio: Form },
    // TODO: この辺りもわざわざpropsで渡さなくても、直接iframe#showcaseから取ってこれる。中途半端に移行したので後で直したい
    props: [
      'formFields',
      'formAnswer',
      'createGuestbookUrl',
      'showcaseId',
      'next',
      'skippableGuestbook',
      'createRecordingUrl'
    ],
    methods: {
      onChange(event) {
        let answerInput = document.getElementById('guestbook_form_answer')
        if (answerInput) {
          answerInput.value = JSON.stringify(event.data)
        }
        const submitButton = document.getElementById('guestbook-update-btn')
        if (submitButton) {
          this.$refs.formio.formio.events._events['formio.checkValidity']()
          document.getElementsByClassName('form-text error').length > 0 ? submitButton.setAttribute('disabled', true) : submitButton.removeAttribute('disabled')
        }
      },
      setOptions() {
        return document.getElementById('guestbook-disabled-form-app') ? { readOnly: true } : {}
      },
      getSubmission() {
        if (this.formAnswer) {
          return { data: JSON.parse(this.formAnswer) }
        }
      },
      getFromFields() {
        return JSON.parse(this.formFields)
      },
      async skip() {
        if (this.createRecordingUrl) {
          const res = await axios.post(this.createRecordingUrl, {
            recording: { showcase_id: this.showcaseId }
          })
          document.getElementById('showcase').dataset.recordingId = res.data.recording_id
        }
        this.showNext()
      },
      async submitForm(submission) {
        const res = await axios.post(this.createGuestbookUrl, {
          guestbook: { form_answer: JSON.stringify(submission.data), showcase_id: this.showcaseId }
        })
        document.getElementById('showcase').dataset.hasGuestbook = true
        document.getElementById('showcase').dataset.recordingId = res.data.recording_id
        this.showNext()
      },
      async showNext() {
        document.getElementById('guestbook-form-wrapper').classList.add('d-none')
        if (this.next === 'cover_page') {
          document.getElementById('cover-page-wrapper').classList.remove('d-none')
        } else {
          document.getElementById('showcase').parentElement.classList.remove('d-none')
          const data = document.getElementById('showcase').dataset
          const DPM = new DriftPhotoModule(data.sid, data)
          await DPM.load()
        }
      }
    }
  }
</script>
