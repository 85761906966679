<template lang='pug'>
div
  .param-inputs.input-group
    input.form-control(v-model='newParamsData.key' placeholder='key')
    span.input-group-text =
    input.form-control(v-model='newParamsData.value' placeholder='value')
    .btn.btn-primary(@click='addParam')
      i.bi.bi-plus-circle.me-1
      | パラメーター追加
  span#alert-text.ms-2(style='color:red' v-if='newParamsData.error')
    | keyとvalueをセットで入力してください。
  div.mt-3(v-if='urlParamsData.length')
    p.mb-2 設定済みURLパラメーター
  .param-inputs.mt-2(v-for='(param, i) in urlParamsData')
    .input-group
      input.form-control(:id='`mp-url-param-${i}`' v-model='param.key' @keyup='onChange')
      span.input-group-text =
      input.form-control(:id='`mp-url-param-${i}-value`' v-model='param.value' @keyup='onChange')
      .btn.btn-danger(@click='deleteParam(i)')
        i.bi.bi-trash
    span#alert-text.ms-2(style='color:red' v-if='param.error')
      | keyとvalueをセットで入力してください。

</template>
<script>
  export default {
    data() {
      return {
        newParamsData: { key: '', value: '', error: false },
        urlParamsData: []
      }
    },
    props: ['mpUrlParamsJson'],
    created() {
      const parsedUrlParams = JSON.parse(this.mpUrlParamsJson)
      for (let key in parsedUrlParams) {
        this.urlParamsData.push({ key: key, value: parsedUrlParams[key], error: false })
      }
    },
    methods: {
      onChange() {
        let urlParamsObject = {}
        this.urlParamsData.forEach(param => {
          if (param.key == '' || param.value == '') {
            param.error = true
          } else {
            param.error = false
            urlParamsObject[param.key] = param.value
          }
        })
        document.getElementById('showcase_mp_url_params').value = JSON.stringify(urlParamsObject)
      },
      addParam() {
        if (this.newParamsData.key == '' || this.newParamsData.value == '') {
          this.newParamsData.error = true
          return
        }

        this.urlParamsData.push(this.newParamsData)
        this.newParamsData = { key: '', value: '', error: false }
        this.onChange()
      },
      deleteParam(index) {
        this.urlParamsData.splice(index, 1)
        this.onChange()
      }
    }
  }
</script>