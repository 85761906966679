let bootstrap_tooltips = null

document.addEventListener('turbolinks:load', () => {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  bootstrap_tooltips = tooltipTriggerList.map(tooltipTriggerEl => {
    let tooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
      trigger: 'manual'
    })
    let hideTimer = null
    tooltipTriggerEl.addEventListener('mouseenter', (e) => {
      tooltip.show()
    })
    tooltipTriggerEl.addEventListener('mouseleave', (e) => {
      hideTimer = setTimeout(() => {
        tooltip.hide()
      }, 100)
    })
    tooltipTriggerEl.addEventListener('inserted.bs.tooltip', (e) => {
      tooltip.tip.addEventListener('mouseenter', (e) => {
        clearTimeout(hideTimer)
      })
      tooltip.tip.addEventListener('mouseleave', (e) => {
        tooltip.hide()
      })
    })

    return tooltip
  })
})

document.addEventListener("turbolinks:before-cache", () => {
  if (bootstrap_tooltips && bootstrap_tooltips.length) {
    bootstrap_tooltips.forEach(tooltip => {
      tooltip.dispose()
    })
    bootstrap_tooltips = null
  }
})
