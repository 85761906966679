var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mb-3", attrs: { id: "guestbook-form-builder" } },
      [
        _c("label", { staticClass: "form-label" }, [_vm._v("フォーム")]),
        _c("formbuilder", {
          ref: "formbuilder",
          attrs: { form: _vm.getFromFields(), options: {} },
          on: { change: _vm.onChange }
        })
      ],
      1
    ),
    _c("div", { staticClass: "card mt-3" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "h6 mb-0" }, [_vm._v("背景画像設定")]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "field mt-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("画像URL")]),
              _c("i", {
                staticClass: "bi bi-question-circle-fill text-secondary ms-1",
                attrs: {
                  "data-bs-toggle": "tooltip",
                  "data-bs-placement": "bottom",
                  title:
                    "URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な画像は5MB以下のpng、jpg、gifです。"
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.background.image_url,
                    expression: "guestbookData.background.image_url"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "module-guestbook-bg-image-url",
                  type: "text",
                  placeholder: "http://xxx.com/background-image.png"
                },
                domProps: { value: _vm.guestbookData.background.image_url },
                on: {
                  keyup: _vm.onChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.guestbookData.background,
                      "image_url",
                      $event.target.value
                    )
                  }
                }
              }),
              _c("input", {
                staticClass: "form-control mt-2",
                attrs: {
                  type: "file",
                  id: "showcase_guestbook_background",
                  name: "showcase[guestbook_background]"
                }
              }),
              _vm.previewImageUrl
                ? _c(
                    "div",
                    { staticClass: "guestbook_background_preview mt-2" },
                    [
                      _c("div", { staticClass: "form-label mb-1" }, [
                        _vm._v("プレビュー"),
                        _vm.showPurgeButton
                          ? _c(
                              "div",
                              {
                                staticClass: "btn btn-sm btn-danger ms-2",
                                on: { click: _vm.onPurgeMedia }
                              },
                              [_c("i", { staticClass: "bi bi-trash-fill" })]
                            )
                          : _vm._e()
                      ]),
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: { src: _vm.previewImageUrl }
                      })
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "field mt-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("画像サイズ")
              ]),
              _c("i", {
                staticClass: "bi bi-question-circle-fill text-secondary ms-1",
                attrs: {
                  "data-bs-toggle": "tooltip",
                  "data-bs-placement": "bottom",
                  "data-bs-html": "true",
                  title:
                    'background-sizeのスタイルを指定出来ます。詳しくは<a href="https://developer.mozilla.org/ja/docs/Web/CSS/background-size" target="_blank">こちら</a>から。'
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.background.size,
                    expression: "guestbookData.background.size"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "module-guestbook-bg-size",
                  type: "text",
                  placeholder: "unset"
                },
                domProps: { value: _vm.guestbookData.background.size },
                on: {
                  keyup: _vm.onChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.guestbookData.background,
                      "size",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "field mt-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("画像リピート")
              ]),
              _c("i", {
                staticClass: "bi bi-question-circle-fill text-secondary ms-1",
                attrs: {
                  "data-bs-toggle": "tooltip",
                  "data-bs-placement": "bottom",
                  "data-bs-html": "true",
                  title:
                    'background-repeatのスタイルを指定出来ます。詳しくは<a href="https://developer.mozilla.org/ja/docs/Web/CSS/background-repeat" target="_blank">こちら</a>から。'
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.background.repeat,
                    expression: "guestbookData.background.repeat"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "module-guestbook-bg-repeat",
                  type: "text",
                  placeholder: "unset"
                },
                domProps: { value: _vm.guestbookData.background.repeat },
                on: {
                  keyup: _vm.onChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.guestbookData.background,
                      "repeat",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "col-12 col-sm-4" }, [
            _c("div", { staticClass: "field mt-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("画像ポジション")
              ]),
              _c("i", {
                staticClass: "bi bi-question-circle-fill text-secondary ms-1",
                attrs: {
                  "data-bs-toggle": "tooltip",
                  "data-bs-placement": "bottom",
                  "data-bs-html": "true",
                  title:
                    'background-positionのスタイルを指定出来ます。詳しくは<a href="https://developer.mozilla.org/ja/docs/Web/CSS/background-position" target="_blank">こちら</a>から。'
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.background.position,
                    expression: "guestbookData.background.position"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "module-guestbook-bg-position",
                  type: "text",
                  placeholder: "unset"
                },
                domProps: { value: _vm.guestbookData.background.position },
                on: {
                  keyup: _vm.onChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.guestbookData.background,
                      "position",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ])
        ]),
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-sm-6" }, [
            _c("div", { staticClass: "field mt-3" }, [
              _c("label", { staticClass: "form-label" }, [_vm._v("カラー")]),
              _c("i", {
                staticClass: "bi bi-question-circle-fill text-secondary ms-1",
                attrs: {
                  "data-bs-toggle": "tooltip",
                  "data-bs-placement": "bottom",
                  title:
                    "パターンカラーを指定してください。「,」区切りでグラデーテョンを指定する事も可能です。"
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.background.trianglify.color,
                    expression: "guestbookData.background.trianglify.color"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "module-guestbook-bg-trianglify-colors",
                  type: "text",
                  placeholder:
                    "単色「#cccccc」、グラデーション「#000000,#ffffff」"
                },
                domProps: {
                  value: _vm.guestbookData.background.trianglify.color
                },
                on: {
                  keyup: _vm.onChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.guestbookData.background.trianglify,
                      "color",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "col-12 col-sm-3" }, [
            _c("div", { staticClass: "field mt-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("セルサイズ")
              ]),
              _c("i", {
                staticClass: "bi bi-question-circle-fill text-secondary ms-1",
                attrs: {
                  "data-bs-toggle": "tooltip",
                  "data-bs-placement": "bottom",
                  title: "セルサイズを指定してください。"
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.background.trianglify.size,
                    expression: "guestbookData.background.trianglify.size"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "module-guestbook-bg-trianglify-size",
                  type: "text",
                  placeholder: "75"
                },
                domProps: {
                  value: _vm.guestbookData.background.trianglify.size
                },
                on: {
                  keyup: _vm.onChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.guestbookData.background.trianglify,
                      "size",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "col-12 col-sm-3" }, [
            _c("div", { staticClass: "field mt-3" }, [
              _c("label", { staticClass: "form-label" }, [
                _vm._v("セルサイズ変動値")
              ]),
              _c("i", {
                staticClass: "bi bi-question-circle-fill text-secondary ms-1",
                attrs: {
                  "data-bs-toggle": "tooltip",
                  "data-bs-placement": "bottom",
                  title: "セルサイズの変動値を指定してください。"
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.background.trianglify.variance,
                    expression: "guestbookData.background.trianglify.variance"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "module-guestbook-bg-trianglify-variance",
                  type: "text",
                  placeholder: "0.75"
                },
                domProps: {
                  value: _vm.guestbookData.background.trianglify.variance
                },
                on: {
                  keyup: _vm.onChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.guestbookData.background.trianglify,
                      "variance",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-check mt-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.guestbookData.for_cilbee,
                    expression: "guestbookData.for_cilbee"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { id: "module-guestbook-for-cilbee", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.guestbookData.for_cilbee)
                    ? _vm._i(_vm.guestbookData.for_cilbee, null) > -1
                    : _vm.guestbookData.for_cilbee
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.guestbookData.for_cilbee,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.guestbookData,
                              "for_cilbee",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.guestbookData,
                              "for_cilbee",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.guestbookData, "for_cilbee", $$c)
                      }
                    },
                    _vm.onChange
                  ]
                }
              }),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "module-guestbook-for-cilbee" }
                },
                [_vm._v("ゲストブックをcilbeeのアンケートとして利用する")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h6 mt-4 mb-0" }, [
      _vm._v("背景パターン設定"),
      _c("i", {
        staticClass: "bi bi-question-circle-fill text-secondary ms-1",
        attrs: {
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "bottom",
          title: "画像のURL指定がある場合はそちらが優先されます。"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }