export default function vueRenderer(id, component, hasOptionDataId=undefined) {
  document.addEventListener('turbolinks:load', async () => {
    const target = document.getElementById(id)
    if (target) {
      const props = hasOptionDataId ? { ...target.dataset, ...document.getElementById(hasOptionDataId).dataset } : target.dataset
      const app = new Vue({
        render: h => h(component, { props: props })
      }).$mount()
      target.appendChild(app.$el)
    }
  })
  document.addEventListener("turbolinks:before-cache", () => {
    let tinymces = [].slice.call(document.querySelectorAll('textarea[id^="tiny-vue"]'))
    tinymces.map((tinymce) => {
      tinymce.__vue__.editor.remove()
    })

    let target = document.getElementById(id)
    if (target) {
      target.innerHTML = ''
    }
  })
}