document.addEventListener('turbolinks:load', async () => {
  const showcaseEditorIframe = document.querySelector('#showcase.editor')
  if (showcaseEditorIframe) {
    const closeButton = document.getElementById('showcase-editor-modal-close')
    closeButton.addEventListener('click', () => {
      document.getElementById('showcase_showcase_editor_data').value = JSON.stringify(document.getElementById('dpm-showcase-editor').__vue__.dpmStore.showcaseEditorData)
    })

    let initialized = false
    document.getElementById('showcase-editor-modal-open').addEventListener('click', async () => {
      if (!initialized) {
        const data = showcaseEditorIframe.dataset
        const DPM = new DriftPhotoModule(data.sid, { ...data, ...{
          bgm: { autoPlay: false, url: data.bgmUrl },
          showShowcaseEditor: true,
          urlParams: JSON.stringify({ play: 1, qs: 1 })
        } } )
        await DPM.load()
        initialized = true
      }
    })
  }
})
