<template lang='pug'>

.row.justify-content-center.mb-3
  .col-12.col-md-6
    .input-group
      input.form-control.search-text(v-model='searchText' type='text' placeholder='ショーケース名 or モデルSID' @keypress.enter='enter')
      button.btn.btn-success(@click='onClick')
        i.bi.bi-search
  template(v-if='admin')
    .col-12
      .card.my-1(v-for='userTag in userTags')
        .card-body.py-1.px-3
          span {{ userTag.name }}
          span.badge.rounded-pill.bg-light.mt-2.mx-1(v-for='tag in userTag.tags')
            label.form-check-label.d-flex.align-items-center.m-1(:for='`tag-${tag.id}`')
              input.form-check-input.me-1.my-0.tag-checkbox(type='checkbox' :id='`tag-${tag.id}`' v-model='checkedTag' :value='tag.id' @change='onChange')
              span.text-dark {{ `#${tag.name}` }}
  template(v-else)
    .text-center
      span.badge.rounded-pill.bg-light.mt-2.mx-1(v-for='tag in userTags.tags')
        label.form-check-label.d-flex.align-items-center.m-1(:for='`tag-${tag.id}`')
          input.form-check-input.me-1.my-0.tag-checkbox(type='checkbox' :id='`tag-${tag.id}`' v-model='checkedTag' :value='tag.id' @change='onChange')
          span.text-dark {{ `#${tag.name}` }}

</template>

<script>
  export default {
    data() {
      return {
        admin: false,
        checkedTag: [],
        searchText: '',
        userTags: []
      }
    },
    created() {
      const container = document.querySelector('#showcase-search-container')
      this.admin = container.dataset.admin === 'true'
      this.userTags = JSON.parse(container.dataset.userTags)

      // NOTE: document.querySelector('#showcase-search-container').__vue__でコンポーネントにアクセス出来ないので、代わりにobserverを使用することにした
      new MutationObserver(_mutations => {
        if (container.dataset.tagId) {
          this.checkedTag.splice(0)
          this.checkedTag.push(container.dataset.tagId)
          this.searchText = ''
          container.dataset.tagId = ''
        }
      }).observe(document.querySelector('#showcase-card-container'), { childList: true, subtree: true })
    },
    methods: {
      enter() {
        this.search()
      },
      onClick() {
        this.search()
      },
      onChange() {
        this.search()
      },
      search() {
        document.getElementById('search-text-field').value = this.searchText
        document.getElementById('search-tag-field').value = this.checkedTag
        document.getElementById('search-submit-button').click()
      }
    }
  }
</script>
